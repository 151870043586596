<script>
import { required, maxLength } from "vuelidate/lib/validators";
import validationMessages from "@/components/validations";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";

export default {
  components: { validationMessages },

  data() {
    return {
      tenant: "",
      sport: "",
      tenant_type: "",
      provider_team_id: "",
      provider: "",
      team_type: "",
      tenants_options:[],
      tenant_type_options: ["team", "league"], // Hardcoded options
      provider_options: ["Infostrada", "Opta", "Enetpulse", "FApi"],
      showModal: false,
      submitted: false,
      tryingToSubmit: false,
      showLoader: false,
      csrf_token: localStorage.getItem("csrf_token"),
      error: null,
    };
  },

  validations: {
    tenant: {
      required,
      maxLength: maxLength(8),
    },
    sport: {
      maxLength: maxLength(16),
    },
    tenant_type: {
      maxLength: maxLength(6), // since ENUM('team', 'league')
    },
    provider_team_id: {
      maxLength: maxLength(64),
    },
    provider: {
      required,
      maxLength: maxLength(16),
    },
    team_type: {
      maxLength: maxLength(16),
    },
  },

  methods: {
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },

    async getTenants() {
        try {
          const response = await Monitor.getTenants();
          this.tenants_options = response.data.tenants.map(tenant => ({ value: tenant, text: tenant }));
        } catch (error) {
          console.error("Error fetching tenants:", error);
        }
    },

    async createTenantSetup() {
      this.tryingToSubmit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToSubmit = false;
        return;
      }

      let formData = new FormData();
      formData.append("tenant", this.tenant);
      formData.append("sport", this.sport);
      formData.append("tenant_type", this.tenant_type);
      formData.append("provider_team_id", this.provider_team_id);
      formData.append("provider", this.provider);
      formData.append("team_type", this.team_type);
      formData.append("csrf_token", this.csrf_token);

      try {
        const response = await Monitor.createTenantSetup(formData);
        if (response.error) {
          this.failedmsg(response.error);
        } else {
          this.successmsg(response.message);
          this.closeModal();
          this.refreshData();
        }
      } catch (error) {
        this.error = error.response?.data?.error || "An error occurred";
        this.failedmsg(this.error);
      } finally {
        this.tryingToSubmit = false;
        this.refreshData();
      }
    },

    successmsg(message) {
      Swal.fire("Success", message, "success");
    },

    failedmsg(message) {
      Swal.fire("Error", message, "error");
    },

    refreshData() {
      this.$emit("onRefresh");
    },

    resetForm() {
      this.tenant = "";
      this.sport = "";
      this.tenant_type = "";
      this.provider_team_id = "";
      this.provider = "";
      this.team_type = "";
      this.$v.$reset();
    },

    modalShown(){
      this.getTenants()
    }
  },
};
</script>


<template>
  <b-modal  @shown="modalShown" id="add_tenant_setup" size="l" v-model="showModal" @hidden="resetForm" title="Tenant Setup" title-class="font-18">
    <form @submit.prevent="createTenantSetup">
      <div class="row">
        <div v-if="$v.$error" class="text-danger">Complete all fields</div>
        <b-card-text>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group label="Tenant" label-for="formrow-tenant-input" class="mb-3">
                <b-form-select
                    v-model="tenant"
                    :options="tenants_options"
                    :class="{ 'is-invalid': submitted && $v.tenant.$error }"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Tenants'" :validationName="$v.tenant"></validationMessages>
              </b-form-group>

              <b-form-group label="Sport" label-for="formrow-sport-input" class="mb-3">
                <b-form-input v-model.trim="sport" ></b-form-input>
              </b-form-group>

              <b-form-group label="Tenant Type" label-for="formrow-tenant_type-input" class="mb-3">
                <b-form-select
                    v-model="tenant_type"
                    :options="tenant_type_options"
                    :class="{ 'is-invalid': submitted && $v.tenant_type.$error }"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Tenant Type'" :validationName="$v.tenant_type"></validationMessages>
              </b-form-group>

              <b-form-group label="Provider Team Id" label-for="formrow-provider_team_id-input" class="mb-3">
                <b-form-input v-model.trim="provider_team_id" ></b-form-input>
              </b-form-group>

              <b-form-group label="Provider" label-for="formrow-provider-input" class="mb-3">
                <b-form-select
                    v-model="provider"
                    :options="provider_options"
                    :class="{ 'is-invalid': submitted && $v.provider.$error }"
                ></b-form-select>
                <validationMessages v-if="submitted" :fieldName="'Provider'" :validationName="$v.provider"></validationMessages>
              </b-form-group>

              <b-form-group label="Team Type" label-for="formrow-team_type-input" class="mb-3">
                <b-form-input v-model.trim="team_type" ></b-form-input>
              </b-form-group>

              <input type="hidden" name="csrf_token" v-model="csrf_token">
            </div>
          </div>
        </b-card-text>
      </div>
    </form>

    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="createTenantSetup" :disabled="tryingToSubmit || showLoader">
        <b-spinner v-show="tryingToSubmit" small></b-spinner>Submit
      </b-button>
    </template>
    <div v-if="isLoading" class="d-flex justify-content-center my-3">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-modal>
</template>
