<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import ModalEditTenantSetup from "@/components/modals/social/modalEditTenantSetup";
import ModalAddTenantSetup from "@/components/modals/social/modalAddTenantSetup";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
    ModalEditTenantSetup,
    ModalAddTenantSetup,
  },
  page: {
    title: "Tenant Setup",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      error: null,


      tableData: [],
      tableDataTournament: [],
      title: "Tenant Setup",
      items: [
        {
          text: "Sport Data",
        },
        {
          text: "Tenant Setup",
          active: true,
        },
      ],

      filterInput: {},
      isBusy: false,
      totalRows: 0,
      totalRowsTournament: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "valid_from",
      sortDesc: true,
      dealData: [],
      tenantSetupData: [],
      fields: [
        {
          key: "id",
          label: "Id",
          visible: false,

        },
        {
          key: "tenant",
          label: "Tenant",
          visible: true,
        },
        {
          key: "sport",
          label: "Sport",
          visible: true,
        },
        {
          key: "tenant_type",
          label: "Tenant Type",
          visible: true,
        },
        {
          key: "provider_team_id",
          label: "Provider Team Id",
          visible: true,
        },
        {
          key: "provider",
          label: "Provider",
          visible: true,
        },
        {
          key: "team_type",
          label: "Team Type",
          visible: true,
        },
        {
          key: "Action",
          label: "Action",
        }
      ],
      statusOptions: [],
      isBusyAll: false,
      totalRowsAll: 0,
      currentPageAll: 1,
      perPageAll: 10,
      pageOptionsAll: [10, 25, 50, 100],
      filterAll: null,
      filterOnAll: [],
      filterData: false,
    };
  },

  async created() {
    await this.getAllTenants();
  },
  methods: {


    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.totalRowsTournament = filteredItems.length;
      this.currentPage = 1;
    },

    async getAllTenants() {

      try {
        this.filterData = true;
        this.isBusy = true;
        var filters = '';
        const response = await Monitor.getAllTenants(filters);
        this.tableData = response.data.data
        this.totalRows = this.tableData.length
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        this.error = error.response.data.error ? error.response.data.error : "";
        this.tableData = []
        this.totalRows = 0
      }
    },



    callModalEditTeamDeal(data) {
      this.$bvModal.show("edit_tenant_setup");
      this.tenantSetupData = data
    },

    callModalAddTenantSetup(data) {
      this.$bvModal.show("add_tenant_setup");
      this.tenantSetupData = data
    },
    removeTenantSetup(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Remove From Tenant Setup!", icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          Monitor.deleteTenantSetup(id)
              .then((response) => {
                const res = response.data.data ? response.data.data : false;
                const error = response.data.error ? response.data.error : '';
                if (res == '' && error == '') {
                  this.getAllTenants();
                  this.successmsg("Tenant Setup Successfully Removed")
                } else {
                  this.failedmsg("Tenant Setup Removal Failed")
                }
              })
              .catch(error => {
                this.failedmsg('Fail!', error)
              });
        }
      });
    },


  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :fields="fields"/>
    <div class="row">
      <div class="col-12">

        <div class="card" v-show="filterData">
          <div class="card-body">
            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
               @click="callModalAddTenantSetup">
              <i class="mdi mdi-plus me-2"></i>Add New Tenant Setup
            </a>

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center">
                <h4 class="card-title m-0">Total Tenant Setup: {{ totalRows }}</h4>
              </div>
            </div>

            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                    id="deals-table_filter"
                    class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0 font-size-12" v-show="filterData">
              <b-table
                  :items="tableData"
                  :busy="isBusy"
                  :fields="fields"
                  :visible="fields.visible"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  show-empty
                  empty-text="No Data Found"
              >
                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0 flo">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-primary" title="Edit Tenant Setup"
                         @click="callModalEditTeamDeal(data.item)">
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove Tenant Setup"
                         @click="removeTenantSetup(data.item.id)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>

                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
            <!--Table end -->

            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->
    <ModalEditTenantSetup :tenantSetupData="tenantSetupData" @onRefresh="getAllTenants()"></ModalEditTenantSetup>
    <ModalAddTenantSetup :tenantSetupData="tenantSetupData" @onRefresh="getAllTenants();"></ModalAddTenantSetup>
    <!-- /MODALS -->

  </Layout>
</template>
